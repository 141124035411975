<template>
  <div>
    <div class="font-weight-bold mb-2">{{file.name}}</div>

    <v-img
      v-if="base64Image"
      :src="base64Image"
      :width="size"
      :height="size"
    ></v-img>
  </div>
</template>

<script>
export default {
  props: {
    file: File,
  },
  data: () => ({
    size: 60,
    base64Image: null,
  }),
  created() {
    this.init()
  },
  methods: {
    init() {
      const reader = new FileReader()
      reader.onloadend = () => {
        this.base64Image = reader.result
      }
      reader.readAsDataURL(this.file)
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
